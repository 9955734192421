<template>
    <router-link to="/account/login"><img src="../../../assets/images/ommed_logo.svg" class="logo" /></router-link>
    <div class="account-form">
        <div class="form">
          <div class="header">
              Confirmação de E-mail
          </div>
          <div class="body">
              <div v-if="emailVerified">
                  <div class="text-center text-4xl my-5">
                      E-mail confirmado
                  </div>
                  <!-- <Button label="Confirmar telefone" @click="requestPhoneConfirmation" /> -->
              </div>
              <div v-if="error['email']">
                  <div class="text-center text-4xl my-5">
                      {{ error['email'][0] }}
                  </div>
                  <!-- <Button label="Confirmar telefone" @click="requestPhoneConfirmation" /> -->
              </div>
              <div v-else>
                  <div class="text-center text-4xl my-5">
                      {{ errorMessage }}
                  </div>
                  <!-- <Button label="Confirmar telefone" @click="requestPhoneConfirmation" /> -->
              </div>
              <!-- <div v-else>
                  <div class="text-center text-4xl my-5">
                      Confirmação de email...
                  </div>
                  <Button label="Reenviar email de confirmação" @click="requestEmailConfirmation" /> 
              </div> -->
          </div>
      </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  SendEmailConfirmationRequest,
  SendPhoneConfirmationRequest,
  VerifyEmailRequest,
} from "../../core/grpc/generated/patientAccount_pb";
import patientAcountService from "../../core/services/patientAcountService";
import useStore from "../../../store";

export default {
    name: 'EmailConfirmation',
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const email = route.params['email'];
        const token = route.params['token'];
        let error = ref([]);
        let errorMessage = ref();

        console.log(store.userInfo);

        let emailVerified = ref(false);

        const verifyEmail = async () => {
            const request = new VerifyEmailRequest();
            request.setEmail(email);
            request.setCode(token);

             const response = await patientAcountService.verifyEmail(request);
             if (response.success == true) {
                emailVerified.value = true;
                setTimeout(() => { 
                    // router.push('/account/login'); 
                    window.location.href = "/account/login";
                }, 1000 * 4);
             } else if(response.success == false && !response.data){
                 errorMessage.value = response.errormessage;
                 if(errorMessage.value == "E-mail já confirmado!") {
                    setTimeout(() => { 
                        // router.push('/account/login'); 
                        window.location.href = "/account/login";
                    }, 1000 * 4);
                 }
             } else {
                 error.value = JSON.parse(response.data);
             }
         };

        onMounted (async () => {
            await verifyEmail();
        });

        return { 
            emailVerified, 
            verifyEmail,
            error, 
            errorMessage,
         }
    }
}

</script>

<style lang="scss">
.account-form {
  .form {
    .header {
      margin: 30px !important;
    }

    .body {
      button {
        width: 275px !important;
        margin: 20px auto;
        display: flex;
      }
    }
  }
}
</style>
